import * as React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { fontFamily } from "../../theme";
import Button from "../components/form/Button";
import { Section as GeneralSection } from "../components/layout/layout-styles";
import Seo from "../components/SEO";

const Section = styled(GeneralSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -140px;
`;

const Heading = styled.h1`
  font-family: ${fontFamily.bold};
  margin: 0;
  font-size: 120px;

  @media only screen and (min-width: 500px) {
    font-size: 200px;
  }
`;

const SubText = styled.span`
  font-size: 24px;
  font-family: ${fontFamily.medium};
  margin-bottom: 24px;

  @media only screen and (min-width: 500px) {
    font-size: 48px;
    margin-bottom: 36px;
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Not Found" description="Page not found" />
      <Section>
        <ContentContainer>
          <Heading>404</Heading>
          <SubText>Page not found</SubText>
          <Button href="/">Go home</Button>
        </ContentContainer>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
